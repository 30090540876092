import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

const { tag, env, version } = require('../../sentry-info.json');
const cid = process.env.SHOPEE_COUNTRY as 'id' | 'ph';

const DsnMapping = {
  id: {
    nonLive:
      'https://a3af9380ad804b0987eedea298fc0561@etracking-uat1.uat.seabank.co.id/sentry/12',
    live:
      'https://eb31a88848d34cd8bb3618fcec57ba64@etracking.seabank.co.id/sentry/8',
  },
  ph: {
    nonLive:
      'https://c1d71930959a4602b31b67ee8f9daea2@etracking-uat1.uat.seabank.ph/sentry/8',
    live:
      'https://98e5c9a069cd41ebb9241f3c0fa0801c@etracking.seabank.ph/sentry/5',
  },
};

const dsn = ['live', 'dr'].includes(env)
  ? DsnMapping[cid].live
  : DsnMapping[cid].nonLive;

Sentry.init({
  dsn,
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],
  release: `${env}@${tag}`,
  dist: version,
});

export default Sentry;

export const captureException = (
  err: Error,
  extras?: Record<string, unknown>,
) => {
  if (process.env.NODE_ENV !== 'development') {
    Sentry.setExtras(extras || {});
    Sentry.captureException(err);
  }
};
